import React, { Component } from 'react'
import { ReactComponent as Arrow } from '../../gfx/circle-arrow.svg'

import { graphql, Link } from 'gatsby'

import { Subscribe } from 'unstated'
import AppContainer from '../AppContainer'

import './style.scss'

const Body = ({ title, shortTitle }) => (
  <>
    <div className="mb-lg-2 mb-xl-4 c-cta-block__icon">
      <Arrow />
    </div>
    <span className="d-none d-lg-block mt-auto c-cta-block__label c-cta-block__label--full">
      {title}
    </span>
    <span className="d-block d-lg-none mt-auto c-cta-block__label c-cta-block__label--short">
      {shortTitle}
    </span>
  </>
)

export default class index extends Component {
  render() {
    const { compact, className, data } = this.props

    const customProps = {
      className: `c-cta-block c-cta-block--${compact &&
        'compact'} d-flex flex-column px-lg-3 px-xl-3 pb-lg-4 pb-xl-4 pt-lg-4 ${className}`,
    }

    if (data) {
      const { title, short_title: shortTitle, link } = data

      return link !== 'contact_form' ? (
        <Link {...customProps} to={link}>
          <Body title={title} shortTitle={shortTitle} />
        </Link>
      ) : (
        <Subscribe to={[AppContainer]}>
          {appContainer => (
            <button {...customProps} onClick={appContainer.openContactModal}>
              <Body title={title} shortTitle={shortTitle} />
            </button>
          )}
        </Subscribe>
      )
    } else {
      return <></>
    }
  }
}

export const CTABlockQuery = graphql`
  fragment ctaBlock on MarkdownRemark {
    frontmatter {
      cta_block {
        title
        short_title
        link
      }
    }
  }
`
