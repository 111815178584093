import React from 'react'

import Layout from '../components/Layout'
import InterestsOverview from '../components/InterestsOverview'
import HeroSlider from '../components/HeroSlider'
import LogoList from '../components/LogoList'
import CardSlider from '../components/CardSlider'
import TestimonialSlider from '../components/TestimonialSlider'
import ArticleSlider from '../components/ArticleSlider'
import CTABlock from '../components/CTABlock'
import SEO from '../components/SEO/index'
import { graphql } from 'gatsby'
import CTABanner from '../components/CTABanner'

//import DataStoriesLaptop from '../components/DataStoriesLaptop'
import DataStoriesOldHome from '../components/DataStoriesOldHome'

function Build_Hero(slides) {
    return slides;
}

function Old_Home_Active() {
     if(typeof window !== `undefined`) {
         if (window.experiment_oldhome) {
            return true;
         }
     }
    return false;
}

class Index extends React.Component {
  render() {
    const { data, location } = this.props
    return (
      <Layout location={location}>
        <SEO
          path={location.pathname}
          frontmatter={data.page.frontmatter}
          title="Home"
        />
        <h1 className="d-none">DataStories</h1>
        <div className="position-relative">
        {Old_Home_Active() ? (
        <DataStoriesOldHome />
      ) : (
        <HeroSlider slides={Build_Hero(data.page.frontmatter.slides)} />
      )}
          <CTABlock data={data.page.frontmatter.cta_block} />
        </div>
        <LogoList title="" />
        <CardSlider data={data.informationCards} />
        <InterestsOverview
          interests={data.interests.categories}
          questions={data.questions}
        />
        <TestimonialSlider />
        <ArticleSlider />
        <div className="bg-light c-footer">
          <CTABanner data={data.page.frontmatter.cta_banner} />
        </div>
      </Layout>
    )
  }
}
// <HeroSlider slides={Build_Hero(data.page.frontmatter.slides)} />
export default Index

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
      }
    }

    informationCards: homeYaml(type: { eq: "info-cards" }) {
      title
      subTitle
      body
      cards {
        type
        title
        link
        color
        image {
          childImageSharp {
            original {
              src
            }
          }
          extension
          publicURL
        }
      }
    }

    page: markdownRemark(fields: { slug: { eq: "/home/" } }) {
      id
      ...SEO
      ...ctaBlock
      ...ctaBanner
      frontmatter {
        slides {
          node: childMarkdownRemark {
            html
            id
            frontmatter {
              teaser_title
              short_title
              long_title
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 1440, maxHeight: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              type
              link {
                label
                to
              }
            }
          }
        }
      }
    }

    interests: homeYaml(type: { eq: "question-categories" }) {
      categories {
        title
        icon {
          publicURL
        }
        icon_hover {
          publicURL
        }
      }
    }
    questions: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "question" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            interest_areas
            question_type
            question_link
          }
        }
      }
    }
  }
`
